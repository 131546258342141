import styled from "@emotion/styled"
import React from "react"
import { device } from "../layout/GlobalStyles"
import spacesHero from "../../images/programs/spaces/hero-spaces.svg"
import spacesLogo from "../../images/programs/logo-spaces-no-title.svg"
import { HeroContainer } from "./Hero-Container"

const Container = styled.div`
  .spaces-logo {
    width: 140px;
    right: 35px;
    position: relative;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    margin-bottom: -70px;
    margin-top: 20px;
    div {
      position: relative;
      bottom: 30px;
    }
    .hero-image {
      margin-top: 60px;
      min-width: 700px;
    }
  }
`

export const SpacesOfGraceHero = () => {
  return (
    <Container>
      <HeroContainer className="container">
        <div>
          <img
            className="spaces-logo"
            src={spacesLogo}
            alt="animated students in classroom"
          />
          <h1>
            Spaces
            <br /> of Grace
          </h1>
        </div>
        <img
          className="hero-image"
          src={`${spacesHero}`}
          alt="animated students in classroom"
        />
      </HeroContainer>
    </Container>
  )
}
