import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Fancybox } from "../Fancy-Box"
import { Button } from "../layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px 42px;
  background: ${colors.primary1};
  .middle-image {
    margin: 72px 0px 25px;
  }
  .first-image {
    margin-bottom: 25px;
  }
  .last-image {
    margin-top: 64px;
  }
  .middle-panel {
    margin: 25px 0px;
  }
  h4 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 48px;
    margin: 0;
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    .video {
      cursor: pointer;
      img {
        transition: 0.2s ease-in-out;
      }
    }
    .video:hover {
      img {
        transform: scale(0.99);
      }
    }
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px 128px;
    .last-image {
      margin-top: 0px;
    }
    > section:last-of-type {
      margin-top: 72px;
    }
    .last-image {
      position: relative;
      left: 50px;
    }
    .first-image {
      position: relative;
      right: 100px;
    }

    img {
      width: 555px;
    }
    .first-text-container {
      margin-bottom: 20px;
      h4 {
        width: 420px;
        margin-bottom: 25px;
      }
      width: 523px;
    }
    .last-text-container {
      width: 513px;
    }
  }
`

export const SpacesPanels = () => {
  return (
    <MainContainer>
      <PanelContainer>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/716495789"
        > */}{" "}
        <Fancybox options={{ infinite: false }}>
          <button
            style={{ background: "none", border: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/716495789"
            color="yellow"
          >
            <StaticImage
              className="video first-image"
              src="../../images/programs/spaces/people-dancing.png"
              placeholder="none"
              quality={100}
              alt="group of people dancing"
            />
          </button>
        </Fancybox>
        {/* </a> */}
        <div className="first-text-container">
          <h4>Home to those who scarcely believe they belong</h4>
          <p>
            Spaces of Grace is MLIFE's spiritual healing space for Black,
            Indigenous, and People of Color (BIPOC) and an affirming faith-space
            for LGBTQ+ identifying groups.
          </p>{" "}
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/716495789"
          > */}
          <Fancybox options={{ infinite: false }}>
            <Button
              data-fancybox="gallery"
              data-src="https://vimeo.com/716495789"
              color="yellow"
            >
              Watch & learn more
            </Button>
          </Fancybox>
          {/* </a> */}
        </div>
      </PanelContainer>
      <PanelContainer reverse>
        <StaticImage
          className="last-image"
          src="../../images/programs/spaces/mwangi-collage.png"
          placeholder="none"
          quality={100}
          alt="collage of Mwangi with speaker and hugging someone"
        />
        <div className="last-text-container">
          <p>
            Spaces of Grace is a collaborative space for allies and conspirators
            willing to disavow their privilege for the greater good.
          </p>
          <p>
            It also a space that celebrate the human spirit and allow people to
            grapple with what it means to be human. We create spaces where it is
            okay not to be okay.
          </p>
          <p>
            At Spaces of Grace, we emphasize openness and service to those
            facing rejection and those afflicted and disempowered to find an
            authentic, supportive, and non-judgmental community. Together, we
            explore the meaning of grace and mercy while creating a world where
            everyone’s equity of voice and outcome exists.
          </p>
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
