import React from "react"
import { SpacesOfGraceHero } from "../../components/heros/Spaces-Of-Grace-Hero"
import { BecomeOneOfUs } from "../../components/Become-One-Of-Us"
import { SpacesPanels } from "../../components/panels/Spaces-Panels"
import { SpacesBio } from "../../components/programs-bio/Spaces-Bio"
import { Seo } from "../../components/Seo"

const SpacesOfGrace = () => {
  return (
    <>
      <Seo
        title="Spaces of Grace - Home to those who scarcely believe they belong"
        description="MLIFE's spiritual healing space for Black, Indigenous, and People of Color (BIPOC) and an affirming faith-space for LGBTQ+ identifying groups."
      />{" "}
      {/* <Nav /> */}
      <SpacesOfGraceHero />
      <SpacesPanels />
      <SpacesBio />
      <BecomeOneOfUs />
      {/* <Footer /> */}
    </>
  )
}

export default SpacesOfGrace
